import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import bulmaCarousel from 'bulma-carousel'

const Carousel = ({ title }) => {
  useEffect(() => {
    bulmaCarousel.attach('.hero-carousel', {
      slidesToScroll: 1,
      slidesToShow: 1,
      infinite: true,
      pagination: false,
      duration: '1200',
      autoplay: true,
    })
  })

  return (
    <section className='hero is-fullheight-with-navbar has-carousel'>
      <div className='hero-carousel'>
        <div className='has-background is-active'>
          <img className='is-background' src='https://wikiki.github.io/images/merry-christmas.jpg' alt='' />
        </div>
        <div className='has-background'>
          <img className='is-background' src='https://wikiki.github.io/images/singer.jpg' alt='' />
        </div>
        <div className='has-background'>
          <img className='is-background' src='https://wikiki.github.io/images/sushi.jpg' alt='' />
        </div>
      </div>
      <div className='hero-body'>
        <div className='columns'>
          <div className='column is-narrow'>
            <div className='box has-background-pastel-pink'>
              <div className='title has-text-pastel-magenta is-1'>
                {title}
              </div>
              <div className='subtitle has-text-pastel-magenta is-3'>
                She really do got that cake tho
              </div>
              <div className='subtitle has-text-pastel-magenta'>
                come getchu some
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Carousel.propTypes = {
  title: PropTypes.string,
}

export default Carousel
